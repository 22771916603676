import React from 'react';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const Form = ({ onSubmit, validationSchema, children }: FormProps) => {
  const methods = useForm({
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
  });

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>{children}</FormWrapper>
    </FormProvider>
  );
};

const FormWrapper = styled.form``;

type FormProps = {
  onSubmit: any;
  children: React.ReactNode;
  validationSchema?: any;
};
